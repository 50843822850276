import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, useMediaQuery, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import QRCode from "react-qr-code";
import CloseIcon from '@mui/icons-material/Close';

const InstallPrompt = () => {
    const [showInstallPrompt, setShowInstallPrompt] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isIOS, setIsIOS] = useState(false);
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        // Check if user has seen the prompt before
        const hasSeenPrompt = localStorage.getItem('hasSeenInstallPrompt-v1');
        if (hasSeenPrompt) return;

        // Check if iOS
        const isIOSDevice = /iPhone/.test(navigator.userAgent) && !window.MSStream;
        setIsIOS(isIOSDevice);

        // Show iOS prompt if not installed
        if (!window.navigator.standalone) {
            setShowInstallPrompt(true);
        }

        // Handle PWA install prompt
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            if (isMobileScreen) {
                setDeferredPrompt(e);
                setShowInstallPrompt(true);
            }
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, [isMobileScreen]);

    const handleInstall = async () => {
        localStorage.setItem('hasSeenInstallPrompt-v1', 'true');
        if (deferredPrompt) {
            deferredPrompt.prompt();
            const { outcome } = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                setDeferredPrompt(null);
            }
        }
        handleClose();
    };

    const handleClose = () => {
        localStorage.setItem('hasSeenInstallPrompt-v1', 'true');
        setShowInstallPrompt(false);
    };

    return (
        <Dialog
            open={showInstallPrompt}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "32px",
                    padding: "1rem"
                }
            }}
        >
            <DialogTitle sx={{
                fontSize: "24px",
                fontWeight: "bold",
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                Install The App
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 16,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {isMobileScreen ? (
                    isIOS ? (
                        <Box>
                            <Typography>
                                Install this application on your iPhone:
                            </Typography>
                            <Typography sx={{ ml: 1.5 }} variant="body2" color="text.secondary">
                                <ul style={{ paddingLeft: '20px', margin: '0' }}>
                                    <li>Tap the Share button</li>
                                    <li>Scroll down and tap "Add to Home Screen"</li>
                                    <li>Tap "Add" to install</li>
                                </ul>
                            </Typography>
                            <img style={{ marginTop: "1rem", borderRadius: "16px" }} width="100%" src="assets/gif/app-download.gif" alt="" />
                        </Box>
                    ) : (
                        <Typography>
                            Install this application on your device for quick and easy access when you're on the go.
                        </Typography>
                    )
                ) : (
                    <Box sx={{ display: 'flex', gap: 4, alignItems: 'start' }}>
                        <Box>
                            <Typography gutterBottom variant="h6">
                                Install on Your Mobile Device
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                1. Open your smartphone camera
                                <br />
                                2. Point it at the QR code
                                <br />
                                3. Follow the prompt to open the link
                                <br />
                                4. Install the app on your mobile device
                            </Typography>
                        </Box>
                        <Box>
                            <QRCode size={150} value={`${window.location.origin}/module`} />
                        </Box>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                {!isIOS && isMobileScreen && (
                    <Button onClick={handleInstall} variant="contained" color="primary">
                        Install
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default InstallPrompt; 