export const generateManifest = (organizationId) => {
    const getFaviconIcon = (orgId) => {
        switch (orgId) {
            case 71 || 9: //EHS and Everett Hight School
                return { src: '/meta-images/everett-32x32.png', sizes: '64x64 32x32 24x24 16x16' };
            case 14://BCH
                return { src: '/meta-images/bch-32x32.png', sizes: '64x64 32x32 24x24 16x16' };
            case 1://City of Everett
                return { src: '/meta-images/city-everett-32x32.png', sizes: '64x64 32x32 24x24 16x16' };
            case 8 || 13 || 17://Healthy Relationships + Northwell Static + Northwell Static Demo
                return { src: '/meta-images/northwell-32x32.png', sizes: '64x64 32x32 24x24 16x16' };
            case 10:// City of Revere
                return { src: '/meta-images/revere-32x32.png', sizes: '64x64 32x32 24x24 16x16' };
            case 11:// Essex County
                return { src: '/meta-images/essex-32x32.png', sizes: '64x64 32x32 24x24 16x16' };
            case 12:// Tallmadge School
                return { src: '/meta-images/talmadge-32x32.png', sizes: '64x64 32x32 24x24 16x16' };
            case 18://Newtown
                return { src: '/meta-images/newtown-32x32.png', sizes: '64x64 32x32 24x24 16x16' };
            case 57: //Middlesex Sherrif Office
                return { src: '/meta-images/middlessex-32x32.png', sizes: '64x64 32x32 24x24 16x16' };
            case 58:// Families First
                return { src: '/meta-images/ff-32x32.png', sizes: '64x64 32x32 24x24 16x16' };
            case 65:// NRPA
                return { src: '/meta-images/nrpa-32x32.png', sizes: '64x64 32x32 24x24 16x16' };
            case 66:// Wellesley Recreation
                return { src: '/meta-images/wellesely-32x32.png', sizes: '64x64 32x32 24x24 16x16' };
            // Add more cases as needed
            default:
                return { src: 'favicon.ico', sizes: '64x64 32x32 24x24 16x16' };
        }
    };

    const get192Icon = (orgId) => {
        switch (orgId) {
            case 71 || 9:
                return { src: '/meta-images/everett-192x192.png', sizes: '192x192' };
            case 14: //BCH
                return { src: '/meta-images/bch-192x192.png', sizes: '192x192' };
            case 1://City of Everett
                return { src: '/meta-images/city-everett-192x192.png', sizes: '192x192' };
            case 8 || 13 || 17://Healthy Relationships + Northwell Static + Northwell Static Demo
                return { src: '/meta-images/northwell-192x192.png', sizes: '192x192' };
            case 10:// City of Revere
                return { src: '/meta-images/revere-192x192.png', sizes: '192x192' };
            case 11:// Essex County
                return { src: '/meta-images/essex-192x192.png', sizes: '192x192' };
            case 12:// Tallmadge School
                return { src: '/meta-images/talmadge-192x192.png', sizes: '192x192' };
            case 18://Newtown
                return { src: '/meta-images/newtown-192x192.png', sizes: '192x192' };
            case 57: //Middlesex Sherrif Office
                return { src: '/meta-images/middlessex-192x192.png', sizes: '192x192' };
            case 58:// Families First
                return { src: '/meta-images/ff-192x192.png', sizes: '192x192' };
            case 65:// NRPA
                return { src: '/meta-images/nrpa-192x192.png', sizes: '192x192' };
            case 66:// Wellesley Recreation
                return { src: '/meta-images/wellesely-192x192.png', sizes: '192x192' };
            // Add more cases as needed
            default:
                return { src: 'logo192.png', sizes: '192x192' };
        }
    };

    const get512Icon = (orgId) => {
        switch (orgId) {
            case 71 || 9:
                return { src: '/meta-images/everett-512x512.png', sizes: '512x512' };
            case 14: //BCH  
                return { src: '/meta-images/bch-512x512.png', sizes: '512x512' };
            case 1://City of Everett
                return { src: '/meta-images/city-everett-512x512.png', sizes: '512x512' };
            case 8 || 13 || 17://Healthy Relationships + Northwell Static + Northwell Static Demo
                return { src: '/meta-images/northwell-512x512.png', sizes: '512x512' };
            case 10:// City of Revere
                return { src: '/meta-images/revere-512x512.png', sizes: '512x512' };
            case 11:// Essex County
                return { src: '/meta-images/essex-512x512.png', sizes: '512x512' };
            case 12:// Tallmadge School
                return { src: '/meta-images/talmadge-512x512.png', sizes: '512x512' };
            case 18://Newtown
                return { src: '/meta-images/newtown-512x512.png', sizes: '512x512' };
            case 57: //Middlesex Sherrif Office
                return { src: '/meta-images/middlessex-512x512.png', sizes: '512x512' };
            case 58:// Families First
                return { src: '/meta-images/ff-512x512.png', sizes: '512x512' };
            case 65:// NRPA
                return { src: '/meta-images/nrpa-512x512.png', sizes: '512x512' };
            case 66:// Wellesley Recreation
                return { src: '/meta-images/wellesely-512x512.png', sizes: '512x512' };
            // Add more cases as needed
            default:
                return { src: 'logo512.png', sizes: '512x512' };
        }
    };
    const getOrgName = (orgId) => {
        switch (orgId) {
            case 71 || 9:
                return { fullName: "Everett Public Schools", shortName: "EPS" };
            case 14: //BCH  
                return { fullName: "BCH", shortName: "BCH" };
            case 1://City of Everett
                return { fullName: "City of Everett", shortName: "Everett" };
            case 8 || 13 || 17://Healthy Relationships + Northwell Static + Northwell Static Demo
                return { fullName: "Northwell Health", shortName: "Northwell" };
            case 10:// City of Revere
                return { fullName: "City of Revere", shortName: "Revere" };
            case 11:// Essex County
                return { fullName: "Essex County", shortName: "Essex" };
            case 12:// Tallmadge School
                return { fullName: "Tallmadge School", shortName: "Tallmadge" };
            case 18://Newtown
                return { fullName: "Newtown", shortName: "Newtown" };
            case 57: //Middlesex Sherrif Office
                return { fullName: "Middlesex Sheriff Office", shortName: "MSO" };
            case 58:// Families First
                return { fullName: "Families First", shortName: "FF" };
            case 65:// NRPA
                return { fullName: "NRPA", shortName: "NRPA" };
            case 66:// Wellesley Recreation
                return { fullName: "Wellesley Recreation", shortName: "Wellesley" };
            // Add more cases as needed
            default:
                return { fullName: "Stickball", shortName: "Stickball" };
        }
    };

    return {
        short_name: getOrgName(organizationId)?.shortName,
        name: getOrgName(organizationId)?.fullName,
        icons: [
            {
                ...getFaviconIcon(organizationId),
                type: "image/png"
            },
            {
                ...get192Icon(organizationId),
                type: "image/png",
                purpose: "any maskable"
            },
            {
                ...get512Icon(organizationId),
                type: "image/png",
                purpose: "any maskable"
            }
        ],
        start_url: ".",
        display: "standalone",
        theme_color: "#000000",
        background_color: "#ffffff",
        prefer_related_applications: false,
        orientation: "portrait",
        favicon: getFaviconIcon(organizationId),
        icon192: get192Icon(organizationId),
        icon512: get512Icon(organizationId)
    };
}; 